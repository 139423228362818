<template>
  <b-container class="mb-7">
    <h1>Goldrush</h1>
    <b-alert v-model="alert.model" variant="danger" dismissible fade>
      {{ alert.text }}
    </b-alert>
    <b-row>
      <CreateGoldrushModal v-if="masterEventState === 'none'" :edit="false" />
      <ActivateGoldrushModal
        v-if="masterEventState === 'created'"
        :id="masterEventData.id"
        :masterTicketFooter="masterEventData.ticketFooter"
      />
      <CreateGoldrushModal v-if="masterEventState === 'created'" :edit="true" :masterEventData="masterEventData" />
      <EditActivatedGoldrushModal
        v-if="masterEventState === 'activated'"
        :id="masterEventData.id"
        :masterTicketFooter="masterEventData.ticketFooter"
      />
      <EditFutureGoldrushRafflesModal
        v-if="masterEventState === 'activated'"
        :id="masterEventData.id"
        @updated="onFutureRafflesUpdated"
      />
    </b-row>
    <b-row>
      <GoldrushMasterEvent v-if="masterEventState !== 'none'" :data="masterEventData" />
    </b-row>

    <b-row class="my-10 ml-0" v-if="canViewRaffleCards && masterEventState !== 'none'">
      <div v-if="loadingRaffleCards" class="flex justify-center items-center">
        <LoadingSpinner />
      </div>
      <div v-else class="flex w-full gap-10">
        <!-- Previous Draw -->
        <GoldrushRaffleCard v-if="previousDraw" :event="previousDraw" />

        <!-- Current Draw -->
        <GoldrushRaffleCard v-if="currentDraw" :event="currentDraw" />
      </div>
    </b-row>

    <b-row class="event-table">
      <b-col>
        <b-form inline>
          <b-checkbox name="show-paid" v-model="hideClosedDraws" switch />
          <label for="show-paid">Hide Closed Draws</label>
        </b-form>
      </b-col>
    </b-row>

    <b-table
      show-empty
      ref="table"
      id="table"
      class="event-table"
      striped
      hover
      :fields="table.fields"
      :per-page="table.perPage"
      :current-page="table.currentPage"
      :items="items"
      :sort-by.sync="table.sortBy"
      :sort-desc.sync="table.sortDesc"
    >
      >
      <template v-slot:table-busy>
        <div class="text-center">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template v-slot:cell(showDetails)="row">
        <b-button size="sm" @click="showDetails(row)" variant="outline-secondary" class="mr-2">
          {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
        </b-button>
      </template>
      <template v-slot:cell(active)="data">
        <b-badge variant="secondary" v-if="data.value === 'ENDED'">Ended</b-badge>
        <b-badge variant="dark" v-if="data.value === 'CLOSED'">Closed</b-badge>
        <b-badge variant="success" v-if="data.value === 'ACTIVE'">Active</b-badge>
        <b-badge variant="light" v-if="data.value === 'PENDING'">Pending</b-badge>
      </template>
      <template v-slot:cell(ticketActive)="data">
        <b-badge variant="primary" v-if="data.value === true">Winner</b-badge>
        <b-badge variant="light" v-if="data.value === false">Roll Over</b-badge>
      </template>
      <template v-slot:row-details="row">
        <b-card-group deck style="background: #ffffff">
          <b-card style="max-width: 20rem; border: none">
            <b-card-title>{{ row.item.name }} #{{ row.item.drawNum }} </b-card-title>
            <b-spinner v-if="showLoadEventSpinner" class="align-middle"></b-spinner>
            <b-card-body v-else>
              <EventTotalsList :event="row.item.event" />
            </b-card-body>
          </b-card>
          <b-card style="max-width: 20rem; border: none">
            <h5>Winner</h5>
            <div v-if="row.item.event && row.item.event.winningTicket">
              <CustomerInfo :customer="row.item.event.winningTicket.order" />
              <b-list-group style="padding-top: 1rem">
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  Order #
                  <b-badge variant="light">
                    <a :href="'/orders?search=' + row.item.event.winningTicket.order.uuid">{{
                      formatUuid(row.item.event.winningTicket.order.uuid)
                    }}</a>
                  </b-badge>
                </b-list-group-item>
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  Ticket #
                  <b-badge variant="light">
                    {{ row.item.event.winningTicket.ticketNumber }}
                  </b-badge>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div class="pick-winner-container" v-else>
              <div v-if="canPickWinner(row.item) && !row.item.barrelDraw">
                <label>Select Winner using RNG</label><br />
                <b-button v-b-modal.pick-winner-modal-rng variant="outline-secondary">Pick Winner</b-button>
              </div>

              <div v-if="canPickWinner(row.item) && row.item.barrelDraw">
                <label for="manual-winner">Select Winner Manually</label>
                <b-form-input
                  id="manual-winner"
                  placeholder="Enter Ticket Number eg. 123-123"
                  v-model="manualWinningTicket"
                ></b-form-input>
                <b-button
                  :disabled="pickManualWinnerButtonDisabled()"
                  v-b-modal.pick-winner-modal-barrel-draw
                  variant="outline-secondary"
                  v-if="canPickWinner(row.item)"
                  style="margin-top: 1rem"
                  >Pick Winner</b-button
                >
              </div>

              <Alert
                v-if="!row.item.winnerTicketId && !canPickWinner(row.item)"
                variant="yellow"
                icon="exclamation"
                show
              >
                You can pick a winner when the event has ended
              </Alert>
              <b-modal
                id="pick-winner-modal-rng"
                centered
                title="Pick Winner"
                @ok="handleOk({ id: row.item.id, barrelDraw: row.item.barrelDraw }, $event)"
                @hide="handleQuit"
                body-class="position-static"
              >
                <b-overlay :show="pickingWinner" no-wrap rounded="sm" />
                <div>
                  <Alert variant="red" icon="exclamation" v-if="errorMessage">{{ errorMessage }}</Alert>
                  <b-alert show variant="warning"
                    >You are about to pick the winner for <strong>Draw #{{ row.item.drawNum }}</strong
                    >. Once a winner is selected you will not be able to modify this event.</b-alert
                  >
                </div>
              </b-modal>

              <b-modal
                id="pick-winner-modal-barrel-draw"
                centered
                title="Pick Winner"
                @ok="handleOk({ id: row.item.id, barrelDraw: row.item.barrelDraw }, $event)"
                @hide="handleQuit"
                body-class="position-static"
              >
                <b-overlay :show="pickingWinner" no-wrap rounded="sm" />
                <div>
                  <Alert variant="red" icon="exclamation" v-if="errorMessage">{{ errorMessage }}</Alert>
                  <b-alert show variant="warning"
                    >You are about to select ticket number <strong>{{ manualWinningTicket }}</strong> as the winner for
                    <strong>Draw #{{ row.item.drawNum }}</strong
                    >. Once a winner is selected you will not be able to modify this event. By clicking
                    <strong>OK</strong> you have confirmed that the Raffle has been reconcilled.</b-alert
                  >
                </div>
              </b-modal>
            </div>
          </b-card>
          <b-card style="border: none">
            <b-link :href="'/tickets/?eventId=' + row.item.id" class="btn rb-link">
              <i class="fa-solid fa-ticket rb-link-icon"></i>View Tickets</b-link
            ><br />
            <b-link :href="'/orders/?eventId=' + row.item.id" class="btn rb-link">
              <i class="fa-solid fa-dollar-sign rb-link-icon"></i>View Orders </b-link
            ><br />
            <b-link :href="'/goldrush/raffle?eventId=' + row.item.id" class="btn rb-link">
              <i class="fa-solid fa-receipt rb-link-icon"></i>View Sales
            </b-link>
          </b-card>
        </b-card-group>
      </template>
    </b-table>

    <b-pagination
      pills
      v-model="table.currentPage"
      :total-rows="table.rows"
      :per-page="table.perPage"
      aria-controls="table"
      align="center"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    ></b-pagination>
  </b-container>
</template>

<script>
import axios from '@/axios';
import CreateGoldrushModal from '@/components/CreateGoldrushModal';
import ActivateGoldrushModal from '@/components/ActivateGoldrushModal';
import EditActivatedGoldrushModal from '@/components/EditActivatedGoldrushModal';
import EditFutureGoldrushRafflesModal from '@/components/modals/EditFutureGoldrushRafflesModal';
import CustomerInfo from '@/components/CustomerInfo';
import GoldrushMasterEvent from '@/components/GoldrushMasterEvent';
import EventTotalsList from '@/components/EventTotalsList';
import EventService from '@/lib/event-service';
import EventServiceV2 from '@/lib/event-service-v2';
import GoldrushServiceV2 from '@/lib/goldrush-service-v2';
import WinnerService from '@/lib/winner-service-v2';
import { isAfter } from 'date-fns';
import Alert from '@/components/ui/Alert';
import GoldrushRaffleCard from '@/components/GoldrushRaffleCard';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  components: {
    Alert,
    GoldrushRaffleCard,
    CreateGoldrushModal,
    ActivateGoldrushModal,
    EditActivatedGoldrushModal,
    EditFutureGoldrushRafflesModal,
    GoldrushMasterEvent,
    CustomerInfo,
    EventTotalsList,
    LoadingSpinner
  },
  data() {
    return {
      alert: {
        text: '',
        model: false
      },
      errorMessage: null,
      hideClosedDraws: true,
      manualWinningTicket: '',
      showLoadEventSpinner: false,
      masterEventState: 'none',
      masterEventData: {},
      pickingWinner: false,
      table: {
        isBusy: false,
        fields: [
          {
            key: 'drawNum',
            label: 'Draw #'
          },
          {
            key: 'startDate',
            formatter: (val) => {
              return this.formatDateTime(this.parseISO(val));
            }
          },
          {
            key: 'endDate',
            formatter: (val) => {
              return this.formatDateTime(this.parseISO(val));
            }
          },
          {
            key: 'active',
            label: 'Status',
            formatter: (isActive, key, item) => {
              if (isActive && !item.ended) {
                return 'ACTIVE';
              } else if (item.ended && item.winnerName) {
                return 'CLOSED';
              } else if (isActive && item.ended) {
                return 'ENDED';
              } else {
                return item.ended ? 'ENDED' : 'PENDING';
              }
            }
          },
          {
            key: 'startingPot',
            formatter: (val) => {
              return this.formatCurrency(val);
            }
          },
          {
            key: 'jackpot',
            formatter: (val) => {
              return this.formatCurrency(val);
            }
          },
          {
            key: 'winnerTicketNumber',
            label: 'Winning Ticket #'
          },
          {
            key: 'winnerName',
            label: 'Customer'
          },
          {
            key: 'ticketActive',
            label: 'Winner'
          },
          {
            key: 'showDetails'
          }
        ],
        rows: 0,
        currentPage: 1,
        perPage: 10,
        sortBy: 'drawNum',
        sortDesc: false
      },
      currentDraw: null,
      previousDraw: null,
      loadingRaffleCards: true,
      canViewRaffleCards: false
    };
  },
  watch: {
    hideClosedDraws: function () {
      this.refreshTable();
    }
  },
  methods: {
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'table');
    },

    onFutureRafflesUpdated() {
      this.refreshTable();
    },

    async handleOk(event, bvModalEvt) {
      bvModalEvt.preventDefault();
      this.pickingWinner = true;
      this.errorMessage = null;

      try {
        await this.handleCloseType(event);
        this.refreshTable();
        this.$router.go();
      } catch (error) {
        if (error.message === 'timeout of 60000ms exceeded') {
          this.errorMessage =
            'The winner is still being picked. Please try again in 30 seconds. If this issue persists contact help@rafflebox.ca.';
        } else {
          this.errorMessage = error.response.data ? error.response.data.errors[0].message : error;
          if (this.errorMessage.includes('has already been closed')) {
            this.refreshTable();
            this.$router.go();
          }
        }
      } finally {
        this.pickingWinner = false;
      }
    },
    handleQuit() {
      this.errorMessage = null;
    },
    async handleCloseType(event) {
      if (event.barrelDraw) {
        const params =
          this.manualWinningTicket && event.barrelDraw ? { winningTicket: this.manualWinningTicket } : null;
        await EventService.manualCloseGoldrushEvent(this.masterEventData.id, event.id, params);
      } else {
        await WinnerService.createWinner(event.id, { status: 'confirmed' });
      }
    },
    pickManualWinnerButtonDisabled() {
      return this.manualWinningTicket.length <= 0;
    },
    async showDetails(row) {
      if (!row.detailsShowing) {
        try {
          this.showLoadEventSpinner = true;

          const response = await EventService.readEvent(row.item.id);
          const event = response.data;

          this.$nextTick(() => {
            row.item.event = event;

            if (event.winningTicket && !event.winningTicket.order) {
              row.item.event.winningTicket.order = {
                name: '',
                addressLine1: '',
                city: '',
                postal: '',
                state: '',
                phone: '',
                email: '',
                uuid: ''
              };
            }

            row.toggleDetails();
          });
        } catch (error) {
          this.handleError(error);
        } finally {
          this.showLoadEventSpinner = false;
        }
      } else {
        row.toggleDetails();
      }
    },
    eventEnded(event) {
      return isAfter(new Date(), this.parseISO(event.endDate));
    },
    canPickWinner(event) {
      if (this.eventEnded(event)) {
        if (!event.winnerTicketId) {
          return true;
        }
      }
      return false;
    },
    async items() {
      if (!this.masterEventData.id) {
        return [];
      }

      const params = {
        page: this.table.currentPage - 1,
        pageSize: this.table.perPage,
        sortBy: 'draw_num',
        sortDir: this.table.sortDesc ? 'desc' : 'asc'
      };

      if (!this.hideClosedDraws) {
        params.includeClosed = true;
      }

      try {
        const response = await GoldrushServiceV2.listEvents(this.masterEventData.id, params);

        this.table.rows = response.pagination.total;

        return response.data;
      } catch (error) {
        this.handleError(`Failed to load Gold Rush events: ${error}`);
        return [];
      }
    },
    async fetchCurrentEvent() {
      if (!this.masterEventData.id) {
        return [];
      }

      const params = {
        pageSize: 1,
        sortBy: 'endDate',
        sortDir: 'desc',
        status: 'not_ended_and_active'
      };

      this.loadingRaffleCards = true;

      try {
        const response = await EventServiceV2.listEvents(params);

        this.currentDraw = response.data[0];
        this.loadingRaffleCards = false;
      } catch (error) {
        this.handleError(`Failed to load current draw: ${error}`);
        this.loadingRaffleCards = false;
        return {};
      }
    },
    async fetchPreviousEvent() {
      this.loadingRaffleCards = true;
      try {
        const response = await EventServiceV2.retrievePreviousEvent(this.$store.state.organization.id);

        this.previousDraw = response;
        this.loadingRaffleCards = false;
      } catch (error) {
        this.handleError(`Failed to load previous draw: ${error}`);
        this.loadingRaffleCards = false;
        return {};
      }
    },
    handleError(text) {
      this.alert.text = text;
      this.alert.model = true;
    }
  },
  async created() {
    // Check feature flag for permissions
    this.canViewRaffleCards = await unleashFeatureEnabled(UnleashKeys.ViewRaffleCards);

    // Get data
    try {
      const response = await axios.get('/goldrush-service/master-event');
      if (!response.data.data.id) {
        this.masterEventState = 'none';
      } else {
        this.masterEventData = { ...response.data.data };
        this.masterEventState = response.data.data.activated ? 'activated' : 'created';
        await this.fetchCurrentEvent();
        await this.fetchPreviousEvent();
      }
    } catch (error) {
      this.handleError(`Failed to load Gold Rush event series ${error}`);
      return;
    }

    this.$refs.table.refresh();
  }
};
</script>

<style scoped>
.container {
  text-align: left;
  margin-left: 115px;
  max-width: 1500px;
}
.raffle-table {
  margin-top: 2rem;
}
.event-table {
  margin-top: 2rem;
}
.pick-winner-container {
  padding-top: 1rem;
}
.rb-link {
  border: 0px none;
  color: #000000;
}
.rb-link-icon {
  min-width: 2.5rem;
  color: #000000;
}
</style>

<template>
  <b-col>
    <b-card title="Gold Rush Event Series" class="col-md-9">
      <b-card-text class="row">
        <b-col>
          <span><strong>Name: </strong>{{ data.name }}</span>
        </b-col>
        <b-col>
          <span><strong>Description: </strong>{{ data.description }}</span>
        </b-col>
      </b-card-text>
      <b-card-text class="row">
        <b-col>
          <span><strong>Days per event: </strong>{{ data.daysPerDraw }}</span>
        </b-col>
        <b-col>
          <span><strong>Number of events: </strong>{{ data.numDraws }}</span>
        </b-col>
      </b-card-text>
      <b-card-text class="row">
        <b-col>
          <span><strong>Starting ticket number: </strong>{{ data.startTicketNumber }}</span>
        </b-col>
        <b-col>
          <span><strong>Print individual tickets: </strong>{{ data.printIndividualTickets ? 'Yes' : 'No' }}</span>
        </b-col>
      </b-card-text>
      <b-card-text class="row">
        <b-col>
          <span><strong>Start date: </strong>{{ formatDateTime(parseISO(data.startDate)) }}</span>
        </b-col>
        <b-col>
          <span><strong>End date: </strong>{{ formatDateTime(parseISO(data.endDate)) }}</span>
        </b-col>
      </b-card-text>
      <b-card-text class="row">
        <b-col>
          <span><strong>Status: </strong>{{ data.activated ? 'Active' : 'Pending' }}</span>
        </b-col>
        <b-col>
          <span><strong>Auto recurring: </strong>{{ data.autoRecurring ? 'Yes' : 'No' }}</span>
        </b-col>
      </b-card-text>
      <b-card-text>
        <b-table striped hover :fields="fields" :items="data.ticketPackages" />
      </b-card-text>
    </b-card>
  </b-col>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { label: 'Tickets', key: 'numTickets' },
        { label: 'Price', key: 'price' },
        { label: 'Series', key: 'series' },
        { label: 'Active', key: 'active' }
      ]
    };
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          name: '',
          startDate: '',
          endDate: '',
          daysPerDraw: 0,
          numDraws: 0,
          description: '',
          printIndividualTickets: false,
          startTicketNumber: 0,
          activated: false,
          autoRecurring: false,
          ticketPackages: [
            {
              numTickets: 0,
              price: 0,
              series: 'A',
              issued: 0,
              limit: 0,
              active: false
            }
          ]
        };
      }
    }
  }
};
</script>

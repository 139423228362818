<template>
  <div>
    <b-list-group>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        Total Raised
        <b-badge variant="light" pill>{{ formatCurrency(event.jackpotDollars) }}</b-badge>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        Total Orders
        <b-badge variant="light" pill>{{ formatNumber(event.numOrders) }}</b-badge>
      </b-list-group-item>
      <b-list-group-item class="d-flex justify-content-between align-items-center">
        Total Tickets Sold
        <b-badge variant="light" pill>{{ formatNumber(event.ticketsSold) }}</b-badge>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
import { formatCurrency, formatNumber } from '@rafflebox-technologies-inc/rafflebox-lib';

export default {
  props: ['event'],
  methods: {
    formatNumber(val) {
      return formatNumber(val);
    },
    formatCurrency(val) {
      return formatCurrency(val);
    }
  }
};
</script>

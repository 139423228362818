<template>
  <div>
    <Badge variant="success" label="Active" v-if="status === 'active'" />
    <Badge variant="danger" label="InActive" v-if="status === 'inactive'" />
    <Badge variant="default" label="Draft" v-if="status === 'pending'" />
    <Badge variant="secondary" label="Ended" v-if="status === 'ended'" />
    <Badge variant="info" label="Closed" v-if="status === 'closed'" />
  </div>
</template>
<script>
import Badge from '@/components/rbComponents/Badge.vue';
export default {
  components: {
    Badge
  },
  props: {
    status: {
      type: String,
      required: true
    }
  }
};
</script>
